<script setup>
import { useChatStore } from "@/stores/chat.store";
import { useUserStore } from "@/stores/user.store";
import { computed } from "vue";
import { useMoment } from "@/plugins/moment";
import ChatDetailHeaderActions from "./actions/ChatDetailHeaderActions.vue";

const chatStore = useChatStore();
const userStore = useUserStore();
const moment = useMoment();

const storedUser = computed(() => ({
  id: userStore.getUserId,
}));
const currentChat = computed(() => chatStore.getCurrentChat);
const typing = computed(() => chatStore.getTyping);
const status = computed(() => {
  const date = chatStore.getChatStatus;
  if (date === "Offline") return "çevrimdışı";
  if (date === "Online") return "çevrimiçi";
  return getDateString(date);
});

const getDateString = (date) => {
  const logDate = moment.unix(date);
  const log_date = logDate.format("HH:mm");
  const now = moment();
  const day = now.diff(logDate, "day");
  if (day === 0) {
    const minute = now.diff(logDate, "minute");
    if (minute <= 1) {
      return `çevrimiçi`;
    }
    if (minute < 60) {
      return `${minute} dakika önce çevrimiçiydi.`;
    }
    const hour = now.diff(logDate, "hour");
    if (hour < 24) {
      return `${hour} saat önce çevrimiçiydi.`;
    }
  } else if (day >= 1) {
    return `Dün ${log_date} saatinde çevrimiçiydi.`;
  } else return "";
};
</script>

<template>
  <header class="chat-detail-header">
    <div class="chat-detail-header-side">
      <span class="icon-button clickable" @click="$router.replace('/chats')">
        <i class="bx bx-arrow-back font-size-150 icon-color"></i>
      </span>
    </div>
    <div class="chat-detail-header-left">
      <is-user-box
        :avatar="
          $cdnAvatarUrl +
          (currentChat.senderID === storedUser.id
            ? currentChat.receiverAvatar
            : currentChat.senderAvatar)
        "
        :username="
          currentChat.senderID === storedUser.id
            ? currentChat.receiverName
            : currentChat.senderName
        "
        :label="typing ? 'yazıyor...' : status"
        :label-active="!!typing"
        :is-online="status === 'çevrimiçi'"
      ></is-user-box>
    </div>
    <div class="chat-detail-header-right">
      <chat-detail-header-actions></chat-detail-header-actions>
    </div>
  </header>
  <is-marquee
    text="Sohbet içerisinde telefon numarası, sosyal medya hesabı, banka bilgisi vs. gibi kişisel bilgilerin paylaşımı ve takas teklifi yasaktır."
    bordered
    class="bl-none bb-none"
  ></is-marquee>
</template>

<style lang="scss">
.chat-detail-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 1rem;
  border-right: 1px solid var(--secondary-background-color);
  background: linear-gradient(
    to bottom,
    var(--theme-primary-color-dark),
    transparent
  );
  .chat-detail-header-left {
    flex-grow: 1;
  }
  .chat-detail-header-side {
    display: none;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 992px) {
  .chat-detail-header {
    .chat-detail-header-side {
      display: flex;
    }
  }
}
</style>
