<script setup>
import ChatDetailMessage from './message/ChatDetailMessage.vue';
import {useChatStore} from '@/stores/chat.store';
import {computed, watch, ref, inject, onMounted, reactive} from 'vue';
import {useUserStore} from '@/stores/user.store';
import {useChatListStore} from '@/stores/chat-list.store';
import {useMoment} from '@/plugins/moment';

const socketService = inject('socketService');

const moment = useMoment();
const chatStore = useChatStore();
const userStore = useUserStore();
const chatListStore = useChatListStore();

const scrollContainer = ref();
const loading = ref(true);
const currentChat = computed(() => chatStore.getCurrentChat);

socketService.on('receiveMessageDetails', (messages) => {
	if (messages && messages.length > 1) {
		chatStore.setLastMessageDate(messages[messages.length - 1].Datetime);
	}
	chatStore.setChatMessages(messages);
});

socketService.on('receiveMessage', (message, senderName, avatar, senderId, chatId) => {
	if (chatId === currentChat.value.chatID) {
		console.log('ChatDetailBody.vue -> socketService.on(\'receiveMessage\') -> message', message, senderName, avatar, senderId, chatId);
		const data = {
			chatId,
			message,
			senderName,
			avatar,
			senderId,
		};
		chatStore.receiveMessage(data);
		chatListStore.receiveMessage(data);
	}
});

const messages = computed(() =>
		chatStore.getMessages.map((m, index, list) => ({
			avatar:
					m.senderID === currentChat.value.senderID
							? currentChat.value.senderAvatar
							: currentChat.value.receiverAvatar,
			message: m.Message,
			isSystem: m.System,
			isDeleted: m.isDeleted,
			isMe: +m.senderID === userStore.getUserId,
			date: m.Datetime,
			isFlood: checkFlood(m, list[index + 1], list[index - 1]),
			isSystemFlood: checkSystemFlood(m, list[index + 1]),
			diffToday: moment().diff(moment(m.Datetime), 'day'),
			diffBetweenDays: checkMessageDays(list[index - 1], m),
		})),
);

const scrollToBottom = () => {
	if (scrollContainer.value) {
		scrollContainer.value.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
			inline: 'nearest',
		});
	}
};

const checkFlood = (a, b, c) => {
	if (!b || a.senderID !== b.senderID || a.System || b.System || !c) {
		return false;
	}
	return (
			moment(Date.parse(a.Datetime)).diff(
					moment(Date.parse(b.Datetime)),
					'minute',
			) < 5
	);
};

const checkSystemFlood = (a, b) => {
	if (!b || a.senderID !== b.senderID || !a.System || !b.System) {
		return false;
	}
	return (
			moment(Date.parse(a.Datetime)).diff(
					moment(Date.parse(b.Datetime)),
					'minute',
			) < 5
	);
};

const checkMessageDays = (a, b) => {
	if (!a) {
		return true;
	}
	const start = moment(Date.parse(b.Datetime));
	start.hours(0);
	start.minutes(0);
	start.seconds(0);
	const end = moment(Date.parse(a.Datetime));
	end.hours(0);
	end.minutes(0);
	end.seconds(0);
	const duration = moment.duration(end.diff(start));
	return Math.abs(duration.asDays()) >= 1;
};

const getDate = (date) => {
	const d = moment(Date.parse(date));
	return d.format('DD MMMM YYYY');
};

watch(messages, scrollToBottom, {flush: 'post'});

onMounted(() => {
	console.log('ChatDetailBody.vue -> onMounted -> loading.value', loading.value);
	if (messages.value.length > 0) {
		scrollToBottom();
		loading.value = false;
		console.log('ChatDetailBody.vue -> onMounted -> loading.value', loading.value);
	}
	console.log('ChatDetailBody.vue -> onMounted -> messages.value', messages.value);
});

</script>

<template>

	<main class="chat-detail-main is-scroll-y scroll-auto scroll-primary">
		<div
				class="chat-detail-messages is-scroll-y scroll-auto scroll-primary"
				ref="scrollContainer"
		>
			<div
					v-for="(chat, index) in messages"
					:key="index"
					class="chat-detail-message-row"
					:class="[
          `chat-${chat.isSystem ? 'center' : chat.isMe ? 'right' : 'left'}`,
          { 'chat-flood': chat.isFlood },
        ]"
			>
				<div
						class="chat-detail-message-col chat-detail-message-date-col"
						v-if="chat.diffBetweenDays"
				>
					<p class="is-description text-center mt-3">
						{{ getDate(chat.date) }}
					</p>
				</div>
				<div class="chat-detail-message-col chat-detail-message-content-col">
					<chat-detail-message
							:avatar="$cdnAvatarUrl + chat.avatar"
							:message="chat.message"
							:is-system="chat.isSystem"
							:is-deleted="chat.isDeleted"
							:date="chat.date"
							:avatar-visible="
              (!chat.isFlood && !chat.isSystem) || chat.diffBetweenDays
            "
							:use-head="
              (!chat.isSystem && !chat.isFlood) ||
              chat.diffBetweenDays ||
              (chat.isSystem && chat.isSystemFlood) ||
              chat.diffBetweenDays
            "
					></chat-detail-message>
				</div>
			</div>
		</div>
	</main>
</template>

<style lang="scss">
.is-scroll-y:not(.hide-parent-scroll).scroll-auto, .is-scroll-y .ant-table-body:not(.hide-parent-scroll).scroll-auto, .is-scroll-y .ant-modal-body:not(.hide-parent-scroll).scroll-auto, .is-scroll-y .ant--card-body:not(.hide-parent-scroll).scroll-auto {
	overflow-y: hidden !important;
}
.chat-detail-main {
	display: flex;
	flex-grow: 1;
	border-right: 1px solid var(--secondary-background-color);
	position: relative;
	overflow: hidden;

	.chat-detail-messages {
		width: 100%;
		position: absolute;
		padding: 0.625rem 1rem;

		.chat-detail-message-row {
			display: flex;
			flex-direction: column;

			.chat-detail-message-col {
				display: flex;
			}

			.chat-detail-message-date-col {
				display: flex;
				justify-content: center;
			}

			&.chat-flood {
				.chat-message {
					margin-top: 0.2rem;
				}
			}

			&.chat-left {
				.chat-detail-message-content-col,
				.chat-message-content-head {
					justify-content: flex-start;
				}

				.chat-message-content-body {
					background-color: var(--theme-primary-color);
					color: var(--theme-text-color-light);
					border-radius: 0 0.93475rem 0.93475rem;
				}

				.chat-message-side {
					margin-right: 0.5rem;
				}
			}

			&.chat-center {
				.chat-detail-message-content-col,
				.chat-message-content-head {
					justify-content: center;
				}

				.chat-message-content-body {
					background: var(--system-message-gradient);
					color: var(--theme-text-color-light);
					border-radius: 0.93475rem;

					a:hover {
						color: var(--theme-primary-dark-color);
					}
				}
			}

			&.chat-right {
				.chat-detail-message-content-col,
				.chat-message-content-head {
					justify-content: flex-end;
				}

				.chat-message-content-body {
					background-color: var(--theme-primary-dark-color);
					color: var(--theme-text-color-light);
					border-radius: 0.93475rem 0.93475rem 0 0.93475rem;
				}

				.chat-message-side {
					margin-left: 0.5rem;
				}

				.chat-message {
					flex-direction: row-reverse;
				}
			}
		}
	}
}
</style>
