<script setup>
import { useChatStore } from "@/stores/chat.store";
import { useUserStore } from "@/stores/user.store";
import { inject, computed } from "vue";
import VueTypes from "vue-types";

defineProps({
  useDescription: VueTypes.bool.def(false),
});

const chatService = inject("chatService");

const userStore = useUserStore();
const chatStore = useChatStore();

const storedUser = computed(() => ({
  id: userStore.getUserId,
}));
const currentChat = computed(() => chatStore.getCurrentChat);
const chatIsArchived = computed(
  () =>
    !!currentChat.value.archivers &&
    currentChat.value.archivers.map((i) => +i).includes(storedUser.value.id)
);

const onConfirm = () => {
  if (chatIsArchived.value) {
    chatService.unarchiveChatById({ chatId: currentChat.value.chatID });
  } else {
    chatService.archiveChatById({ chatId: currentChat.value.chatID });
  }
};
</script>

<template>
  <a-popconfirm
    :title="
      chatIsArchived
        ? 'Bu sohbeti arşivden çıkarmak istediğinize emin misiniz?'
        : 'Bu sohbeti arşivlemek istediğinize emin misiniz?'
    "
    ok-text="Evet"
    placement="top"
    cancel-text="Hayır"
    @confirm="onConfirm"
  >
    <a
      class="chat-header-action clickable"
      href="javascript:;"
      :class="{ 'with-desc': useDescription }"
    >
      <i
        class="bx icon-color font-size-150"
        :class="!chatIsArchived ? 'bx-archive-out' : 'bx-archive-in'"
      ></i>
      <span class="is-description" v-if="useDescription">{{
        chatIsArchived ? "Arşivden Çıkar" : "Arşivle"
      }}</span>
    </a>
  </a-popconfirm>
</template>
