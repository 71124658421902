<script setup>
import { inject } from "vue";
import ChatArchiveButton from "./ChatArchiveButton.vue";
import ChatUserNoteButton from "./ChatUserNoteButton.vue";
import ChatUserOrdersButton from "./ChatUserOrdersButton.vue";

const displayWidth = inject("displayWidth");
</script>

<template>
  <div class="chat-detail-header-actions">
    <template v-if="displayWidth <= 762">
      <a-dropdown>
        <span class="icon-button clickable">
          <i class="bx bx-dots-vertical-rounded font-size-150 icon-color"></i>
        </span>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <chat-archive-button use-description></chat-archive-button>
            </a-menu-item>
            <a-menu-item>
              <chat-user-note-button use-description></chat-user-note-button>
            </a-menu-item>
            <a-menu-item>
              <chat-user-orders-button
                use-description
              ></chat-user-orders-button>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </template>
    <template v-else>
      <chat-archive-button></chat-archive-button>
      <chat-user-note-button></chat-user-note-button>
      <chat-user-orders-button></chat-user-orders-button>
    </template>
  </div>
</template>

<style lang="scss">
.chat-detail-header-actions {
  display: flex;
  .chat-header-action {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.ant-dropdown-menu-title-content > a.chat-header-action {
  display: flex;
  align-items: center;
  i {
    margin-right: 0.5rem;
  }
}
</style>
