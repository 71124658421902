<script setup>
import {useChatStore} from '@/stores/chat.store';
import {onMounted, computed, ref} from 'vue';

const emit = defineEmits(['is:send', 'is:typing']);

const chatStore = useChatStore();

const error = computed(() => chatStore.getError);

const input = ref();
const message = ref('');

const addEmojiMessage = (event) => {
	message.value += event.detail.emoji.unicode;
};

const handleSendMsg = () => {
	if (message.value) {
		emit('is:send', message.value);
		message.value = '';
	}
};

</script>

<template>
  <span class="text-primary chat-error" v-if="error.errMessage">{{
		  error.errMessage
	  }}</span>
	<footer class="chat-detail-footer base-form">
		<div class="chat-detail-footer-left">
			<is-emoji-picker @is:click="addEmojiMessage"></is-emoji-picker>
		</div>
		<div class="chat-detail-footer-center">
			<a-input
					v-model:value="message"
					max-length="500"
					placeholder="Bir mesaj yazın"
					size="large"
					ref="input"
					@keydown.enter="handleSendMsg"
					@change="$emit('is:typing')"
			></a-input>
		</div>
		<div class="chat-detail-footer-right">
      <span class="chat-send-button" @click="handleSendMsg">
        <i class="bx bx-send font-size-150 icon-color"></i>
      </span>
		</div>
	</footer>
</template>

<style lang="scss">
.chat-error {
	font-size: 0.75rem;
	padding: 0.25rem 4rem;
}

.chat-detail-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.625rem 1rem;
	border-top: 1px solid var(--secondary-background-color);
	border-right: 1px solid var(--secondary-background-color);

	.chat-detail-footer-left {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-right: 0.5rem;
	}

	.chat-detail-footer-center {
		display: flex;
		flex-grow: 1;
	}

	.chat-detail-footer-right {
		padding-left: 0.5rem;

		.chat-send-button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.5rem;
		}
	}
}
</style>
