<script setup>
import ChatDetailBody from '@/features/chat/detail/body/ChatDetailBody.vue';
import ChatDetailHeader from '@/features/chat/detail/head/ChatDetailHeader.vue';
import ChatDetailFooter from '@/features/chat/detail/footer/ChatDetailFooter.vue';
import {useUserStore} from '@/stores/user.store';
import {useChatStore} from '@/stores/chat.store';
import {computed, onMounted, inject, ref, onUnmounted} from 'vue';
import {useRoute} from 'vue-router';
import {debounce} from '@/utility/utility';
import {router} from '@/router';

const chatService = inject('chatService');
const socketService = inject('socketService');


const userStore = useUserStore();
const chatStore = useChatStore();
const route = useRoute();

const storedUser = computed(() => ({
	id: userStore.getUserId,
	username: userStore.getUserName,
	avatar: userStore.getAvatar,
}));
const receiver = computed(() =>
		calculateReceiver(chatStore.getCurrentChat, storedUser.value),
);

const useStatusInterval = () => {
	return ref(
			setInterval(() => {
				chatService.getUserOnlineStatus({
					username: receiver.value.username,
				});
			}, 10000),
	);
};

const statusInterval = useStatusInterval();

const calculateReceiver = (chat, stored) => {
	if (chat.senderID === stored.id) {
		return {
			id: chat.receiverID,
			username: chat.receiverName,
			avatar: chat.receiverAvatar,
		};
	}
	else {
		return {
			id: chat.senderID,
			username: chat.senderName,
			avatar: chat.senderAvatar,
		};
	}
};
socketService.on('receiveMessageDetails', (messages) => {
	if (messages && messages.length > 1) {
		chatStore.setLastMessageDate(messages[messages.length - 1].Datetime);
	}
	chatStore.setChatMessages(messages);
});
const sendMessage = (msg) => {
	chatService.sendMessage({
		message: msg,
		toUser: {
			userId: receiver.value.id,
			username: receiver.value.username,
			avatar: receiver.value.avatar,
		},
		fromUser: {
			userId: storedUser.value.id,
			username: storedUser.value.username,
			avatar: storedUser.value.avatar,
		},
		chatId: route.params.id,
	});
	console.log('mesaj gönderildi');

	chatStore.addMessage({
		message: msg,
		receiverId: receiver.value.id,
		Datetime: new Date().toISOString(),
		chatId: route.params.id,
		date: new Date().toISOString(),
		userId: storedUser.value.id,
	});
};

const sendTyping = debounce(() => {
	chatService.sendTyping({
		chatId: route.params.id,
		username: receiver.value.username,
	});
}, 300);

const checkAndSetTemp = () => {
	if (route.query.avatar && route.query.id && route.query.name) {
		chatStore.setTemp({
			chatID: route.params.id,
			receiverAvatar: route.query.avatar.replace(
					'https://cdn.itemsatis.com/avatar/',
					'',
			),
			receiverID: +route.query.id,
			receiverName: route.query.name,
			senderID: storedUser.value.id,
			senderName: storedUser.value.username,
			senderAvatar: storedUser.value.avatar,
		});
		router.replace({
			path: `/chats/${route.params.id}`,
		});
	}
};

const setVirtualHeight = () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const listenResize = () => {
	window.addEventListener('resize', setVirtualHeight);
};

onMounted(() => {
	setVirtualHeight();
	listenResize();
	// chatStore.clear();
	checkAndSetTemp();
	chatService.onMounted({chatId: route.params.id});
});

onUnmounted(() => {
	clearInterval(statusInterval.value);
});
</script>

<template>
	<div class="chat-detail-view">
		<a-row class="detail-content">
			<chat-detail-header></chat-detail-header>
			<chat-detail-body></chat-detail-body>
			<chat-detail-footer
					@is:send="sendMessage"
					@is:typing="sendTyping"
			></chat-detail-footer>
		</a-row>
		<a-row class="chat-detail-sidebar-row" v-if="$route.meta.sidebar">
			<router-view v-slot="{ Component, route }" name="sidebar">
				<transition name="fade" mode="out-in">
					<component :is="Component" :key="route.path"/>
				</transition>
			</router-view>
		</a-row>
	</div>
</template>

<style lang="scss">
.chat-detail-view {
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;

	.detail-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.chat-detail-sidebar-row {
		width: 100%;
	}

	.chat-detail-sidebar {
		min-width: 250px;
		width: 100%;
		background: linear-gradient(
						to top,
						var(--secondary-background-color),
						var(--default-background-color)
		);
	}
}

@media screen and (max-width: 1600px) {
	.chat-detail-view {
		position: relative;

		.chat-detail-sidebar-row {
			position: absolute;
			z-index: 5;
			width: 100%;
			height: 100%;
		}
	}
}
</style>
